import React, { useEffect, useRef, useState } from 'react';

//Assets
import defaultLogo from '../../../assets/images/FullColorOnlyLogo.png';
import airLogo from '../../../assets/images/TeamstersAirLogo.png';

//Mui Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//component Styles
const componentStyles = {
  componentContainer: (isLandingPage, rowImg) => ({
    display: 'flex',
    flexWrap: rowImg ? 'wrap' : '',
    flexDirection: isLandingPage || rowImg ? 'column' : 'row',
    justifyContent: isLandingPage || rowImg ? 'center' : 'flex-start',
    alignItems: isLandingPage || rowImg ? 'center' : 'flex-start',
    padding: isLandingPage ? '0px' : '0px 0px 5px 0px',
  }),
  logoContainer: (isLandingPage) => ({
    margin: isLandingPage ? '10px auto' : '6px 0px 0px 0px',
    minHeight: '80px',
    minWidth: '80px',
  }),
  image: (rowImg) => ({
    maxHeight: '86px',
    minHeight: '80px',
    objectFit: 'contain',
    width: rowImg ? '100%' : '',
  }),
  titleContainer: (isLandingPage, rowImg) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: isLandingPage || rowImg ? 'center' : 'flex-start',
    maxWidth: isLandingPage ? '320px' : '450px',
    padding: isLandingPage || rowImg ? '0px' : '0px 0px 0px 20px',
    margin: isLandingPage ? '5px auto 15px' : '0px',
  }),
};

export default function ExternalEnrollmentHeader(props) {
  //style variable: center header for landing page ONLY - value from props
  const isLandingPage = props?.isLandingPage ? props.isLandingPage : false;

  const [selectedEnrollment, setSelectedEnrollment] = useState('');
  useEffect(() => {
    if (props.selectedEnrollment) {
      setSelectedEnrollment(props.selectedEnrollment);
    }
  }, [props.selectedEnrollment]);

  const isAirPlan = (enrollObj) => {
    var benefitOffsetSearch =
      'Aeromedical Certification Services included to work with FAA on your behalf.';
    return (
      enrollObj?.stdPlan?.options?.filter(
        (opt) => opt.benefitOffsets === benefitOffsetSearch
      ).length > 0
    );
  };

  //IMAGE FETCH LOGIC

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    // console.log('logo img error',FallbackLogo)
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = defaultLogo;
  };

  const logoRef = useRef(null);
  const getAspectRatio = (img) => {
    if (!img) return 1;
    const aspectRatio = img.naturalWidth / img.naturalHeight;
    console.log('aspectRatio', img, aspectRatio);
    return aspectRatio;
  };
  const rowImg = getAspectRatio(logoRef?.current) > 2;

  return (
    <Box sx={componentStyles.componentContainer(isLandingPage, rowImg)}>
      <Box sx={componentStyles.logoContainer(isLandingPage)}>
        <img
          alt="logo"
          src={
            isAirPlan(props.selectedEnrollment)
              ? airLogo
              : props.agentEnroller && props.fullMemberObj?.organizationId > 0
              ? `api/image/organization/${props.fullMemberObj?.organizationId}`
              : '/api/image/organization'
          }
          style={componentStyles.image(rowImg)}
          ref={logoRef}
          onError={handleLogoError}
        />
      </Box>
      <Box sx={componentStyles.titleContainer(isLandingPage, rowImg)}>
        <Typography variant="subtitle1" fontWeight="600">
          {isAirPlan(selectedEnrollment)
            ? 'Teamsters Voluntary Income Protection VIP+ Air'
            : selectedEnrollment?.name || 'Enrollment'}
        </Typography>
      </Box>
    </Box>
  );
}
